import {checkForUpdates, hasPendingUpdate, reload} from "./serviceWorkerRegistration";
import {getFromLocalStorage, saveToLocalStorage} from "./localStorageZugriff";

const CHECK_INTERVAL = 4 * 60 * 60 * 1000; // 4 Stunden

export async function manageServiceWorker(){
    let nextUpdateCheck = getFromLocalStorage("dh_next_update_check");
    if (shouldPerformUpdate(nextUpdateCheck)) {
        const result = await checkForUpdates();
        saveToLocalStorage("dh_next_update_check", calcNextUpdateCheck());
        console.log(result);
        return result.waiting || false;
    } else {
        const result = await hasPendingUpdate();
        console.log(result);
        return result.waiting || false;
    }
}

function shouldPerformUpdate(nextUpdateCheck) {
    let now = new Date().getTime();
    let next = new Date().getTime();
    if (nextUpdateCheck != null) {
        next = nextUpdateCheck;
    }
    return now >= next;
}

function calcNextUpdateCheck() {
    return new Date().getTime() + CHECK_INTERVAL;
}

export async function reloadServiceWorker() {
    await reload();
}
