import {Alert, Button, Dialog, DialogActions, Snackbar, ThemeProvider} from "@mui/material";
import {CustomFontTheme} from "../x01/CustomFontTheme";
import React, {useCallback, useEffect, useState} from 'react';
import {getFromLocalStorage} from "../localStorageZugriff";
import FetchUtil from "../util/FetchUtil";


export default function BoardDialog({onCancel, onSave}) {

    const [errorMessage, setErrorMessage] = useState(undefined);

    const [url] = useState(getFromLocalStorage("dh_url"));
    const [token] = useState(getFromLocalStorage("dh_token"));
    const [boardToken] = useState(getFromLocalStorage("dh_board_token"));

    const [boards, setBoards] = useState([]);

    const ladeBoards = useCallback(() => {
        setErrorMessage(undefined);

        FetchUtil.fetchGet(url + "/boards", token, boardToken,
          json => {
              setBoards(json);
          },
          responseNotOk => {
              setErrorMessage("Fehler: " + responseNotOk.message)
          },
          error => {
              setErrorMessage("Fehler: " + error.message)
          }
        );
    }, [url, boardToken, token]);

    useEffect(() => {
        ladeBoards()
    }, [ladeBoards]);

    const storeNewBoard = (board) => {
        onSave(board.token, board.bezeichnung, board.autodartsBoardId);
        onCancel();
    }

    return <Dialog onClose={onCancel} open={true}>
        <Snackbar open={errorMessage !== undefined} autoHideDuration={3000} onClose={() => setErrorMessage(undefined)}
                  anchorOrigin={{vertical: "top", horizontal: "center"}}>
            <Alert onClose={() => setErrorMessage(undefined)} severity="error" sx={{width: '100%', fontSize: 20}}>
                {errorMessage}
            </Alert>
        </Snackbar>
        <div style={{padding:20, fontSize:25}}>
            {boards.length === 0 && <div>Keine Boards vorhanden.</div>}
            {boards.map(board => {
                return <div style={{marginBottom:10}}>
                    <div style={{cursor:"pointer"}} onClick={() => storeNewBoard(board)}>
                        {board.bezeichnung}
                        {board.autodartsBoardId && <img  src="/images/autodarts_small.svg" style={{width:25, height:25, marginLeft:5}} alt="Autodarts" />}
                    </div>
                </div>
            })}
        </div>
        <DialogActions style={{padding:20}}>
            <ThemeProvider theme={CustomFontTheme}>
                <Button variant="text" onClick={() => onCancel()}>Abbrechen</Button>
            </ThemeProvider>
        </DialogActions>
    </Dialog>
}