import './dashboard.css';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SlideshowOutlinedIcon from '@mui/icons-material/SlideshowOutlined';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import SportsVolleyballIcon from '@mui/icons-material/SportsVolleyball';
import TvIcon from '@mui/icons-material/Tv';
import React, {useCallback, useEffect, useState} from 'react';
import {NAVIGATION_ITEM} from "../constants/navigationItems";
import {NavLink} from "react-router-dom";
import {manageServiceWorker, reloadServiceWorker} from "../useServiceWorker";
import UpdateDialog from "./UpdateDialog";
import deleteGames from "../deleteGames";
import {isSmartphone, isTablet, isTabletHochformat} from "../util/GeraeteArtUtil";
import ComputerIcon from '@mui/icons-material/Computer';
import TabletAndroidIcon from '@mui/icons-material/TabletAndroid';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import {getFromLocalStorage, saveToLocalStorage} from "../localStorageZugriff";
import {Button} from "@mui/material";
import LiveViewControlDialog from "../liveview/LiveViewControlDialog";

function Dashboard() {

    const isDesktop = !isTablet() && !isSmartphone();

    const [showGeraeteauswahlArea, setShowGeraeteauswahlArea] = useState(false);
    const [selectedGeraet, setSelectedGeraet] = useState(undefined);

    const [showLiveViewControlDialog, setShowLiveViewControlDialog] = useState(false);

    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    })

    const version = process.env.REACT_APP_VERSION;
    const [showReload, setShowReload] = useState(false);
    useEffect(() => {
        const callAsync = async () => {
            const showReload = await manageServiceWorker();
            console.log("showReload: " + showReload);
            if( showReload) {
                update();
            }
        }
        // nach 10 Sek wird nach Update gesucht. Wenn er direkt geschaut hat, hat der Browser
        // das Update noch nicht erkannt.
        setTimeout(() => {
            callAsync();
        }, 10000);
    }, []);

    useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);


    useEffect(() => {
        if( getFromLocalStorage("dh_default_geraetetyp") === undefined ) {
            setShowGeraeteauswahlArea(true);
        }
    }, [])

    useEffect(() => {
        deleteGames();
    }, [])

    const onKeyDown = useCallback((event) => {
        if( showLiveViewControlDialog ) {
            event.preventDefault();
            event.stopPropagation();
            return;
        }
        switch (event.key) {
            case "F1":
            case "1":
                window.open(NAVIGATION_ITEM.GAME.route, "_self"); event.preventDefault(); break;
            case "F2":
            case "2":
                window.open(NAVIGATION_ITEM.GAMEOVERVIEW_OPEN.route, "_self"); event.preventDefault(); break;
            case "F3":
            case "3":
                window.open(NAVIGATION_ITEM.GAMEOVERVIEW_CLOSED.route, "_self"); event.preventDefault(); break;
            case "F4":
            case "4":
                window.open(NAVIGATION_ITEM.TOURNAMENT.route, "_self"); event.preventDefault(); break;
            case "F5":
            case "5":
                window.open(NAVIGATION_ITEM.SETTINGS.route, "_self"); event.preventDefault(); break;
            case "F9":
            case "9":
                openLiveViewControl(); event.preventDefault(); break;
            case "u":
            case "U":
                update();
                event.preventDefault();
                break;
            default: break;
        }
        event.stopPropagation();
    }, [showLiveViewControlDialog]);

    useEffect(() => {
        window.addEventListener("keydown", onKeyDown);

        return () => window.removeEventListener("keydown", onKeyDown);
    }, [onKeyDown]);

    const openLiveViewControl = () => {
        setShowLiveViewControlDialog(true);
    }

    const update = async () => {
        await setShowReload(false);
        reloadServiceWorker()
        let url = "https://www.darthelfer.de/#/public/scorer/update?type=";
        if( window.location.href.includes("scorer.") ) {
            url += "prod";
        } else {
            url += "test";
        }
        window.open(url, "_self");
    }

    const getKachelStyle = () => {
        if( isSmartphone()) {
            return {height:80};
        } else {
            return {height:100};
        }
    }

    if( showGeraeteauswahlArea ) {
        let geraeteauswahlMessage = "";
        if( selectedGeraet === "desktop") {
            geraeteauswahlMessage = "Desktop / Laptop";
        } else if( selectedGeraet === "tablet") {
            geraeteauswahlMessage = "Tablet";
        } else if( selectedGeraet === "smartphone") {
            geraeteauswahlMessage = "Smartphone";
        }
        return <div className="bg" style={{padding:20, color:"white"}}>
            <h3>
                Damit der Scorer für dich optimiert angezeigt werden kann musst du dein Gerät auswählen.
            </h3>
            <div style={{fontSize:14, marginBottom:20}}>
                * Diese Einstellung kannst du jederzeit unter 'Einstellungen-&gt;Anzeige->Geräte' erneut ändern.
            </div>
            {selectedGeraet !== undefined &&
                <div style={{backgroundColor: "white", color:"black", marginBottom:20, fontSize:20, padding:10}}>
                    <div>Frage:</div>
                    <div>Möchtest du den gewünschten<br/>Gerätetypen: '{geraeteauswahlMessage}'<br/>wirklich speichern?</div>
                    <div>
                        <Button style={{fontSize:20}} variant="text" onClick={() => {
                            saveToLocalStorage("dh_default_geraetetyp", selectedGeraet);
                            setSelectedGeraet(undefined);
                            setShowGeraeteauswahlArea(false);
                        }}>Ja</Button>
                        <Button style={{fontSize:20}} variant="text" onClick={() => setSelectedGeraet(undefined)}>Nein</Button>
                    </div>
                </div>
            }

            <div className="grid" style={{alignItems: "center", border:"1px solid gray", backgroundColor:"#ac1c27", marginBottom:20, cursor:"pointer"}} onClick={() => setSelectedGeraet("desktop")}>
                <div className="col" style={{justifyContent: "left", alignItems: "center", display:"flex"}}>
                    <ComputerIcon style={{fontSize:50, marginRight:20, color:"white"}}/><span style={{fontSize: 26}}>Desktop / Laptop</span>
                </div>
            </div>
            <div className="grid" style={{alignItems: "center", border:"1px solid gray", backgroundColor:"#ac1c27", marginBottom:20, cursor:"pointer"}} onClick={() => setSelectedGeraet("tablet")}>
                <div className="col" style={{justifyContent: "left", alignItems: "center", display:"flex"}}>
                    <TabletAndroidIcon style={{fontSize:50, marginRight:20, color:"white"}}/><span style={{fontSize: 26}}>Tablet</span>
                </div>
            </div>
            <div className="grid" style={{alignItems: "center", border:"1px solid gray", backgroundColor:"#ac1c27", marginBottom:20, cursor:"pointer"}} onClick={() => setSelectedGeraet("smartphone")}>
                <div className="col" style={{justifyContent: "left", alignItems: "center", display:"flex"}}>
                    <SmartphoneIcon style={{fontSize:50, marginRight:20, color:"white"}}/><span style={{fontSize: 26}}>Smartphone</span>
                </div>
            </div>
        </div>
    }

    return <div className="bg" style={{padding:20, cursor:"pointer"}}>
        {showReload && <UpdateDialog onUpdate={update}/>}
        {(isSmartphone() || isTabletHochformat(dimensions)) &&
            <a href="https://www.darthelfer.de" target="_self">
                <img src="/images/darthelfer_quer_weiss.png" alt="darthelfer.de"
                     title={"scorer.darthelfer.de - " + version} style={{width: "100%", marginBottom:20}}/>
            </a>
        }
        <div className="grid" style={{margin:0}}>
            <div className="col-12 md:col-12 lg:col-6" style={{color:"white"}}>
                <NavLink to={NAVIGATION_ITEM.GAME.route} style={{textDecoration: "none", color:"white"}}>
                    <div className="grid" style={{alignItems: "center", border:"1px solid gray", backgroundColor:"#ac1c27", marginBottom:20, ...getKachelStyle()}}>
                            <div className="col" style={{justifyContent: "left", alignItems: "center", display:"flex"}}>
                                <AddCircleOutlineIcon style={{fontSize:50, marginRight:20, color:"white"}}/><span style={{fontSize: isSmartphone() ? 20 : 30}}>Neues Spiel erstellen {isDesktop && <span style={{marginLeft:20}}>(F)1</span>}</span>
                            </div>
                    </div>
                </NavLink>
                <NavLink to={NAVIGATION_ITEM.GAMEOVERVIEW_OPEN.route} style={{textDecoration: "none", color:"white"}}>
                    <div className="grid" style={{alignItems: "center", border:"1px solid gray", backgroundColor:"#ac1c27", marginBottom:20, ...getKachelStyle()}}>
                            <div className="col" style={{justifyContent: "left", alignItems: "center", display:"flex"}}>
                                <SlideshowOutlinedIcon style={{fontSize:50, marginRight:20, color:"white"}}/><span style={{fontSize: isSmartphone() ? 20 : 30}}>Offene Spiele {isDesktop && <span style={{marginLeft:20}}>(F)2</span>}</span>
                            </div>
                    </div>
                </NavLink>
                <NavLink to={NAVIGATION_ITEM.GAMEOVERVIEW_CLOSED.route} style={{textDecoration: "none", color:"white"}}>
                    <div className="grid" style={{alignItems: "center", border:"1px solid gray", backgroundColor:"#ac1c27", marginBottom:20, ...getKachelStyle()}}>
                        <div className="col" style={{justifyContent: "left", alignItems: "center", display:"flex"}}>
                            <FolderOpenOutlinedIcon style={{fontSize:50, marginRight:20, color:"white"}}/><span style={{fontSize: isSmartphone() ? 20 : 30}}>Beendete Spiele {isDesktop && <span style={{marginLeft:20}}>(F)3</span>}</span>
                        </div>
                    </div>
                </NavLink>
                <NavLink to={NAVIGATION_ITEM.TOURNAMENT.route} style={{textDecoration: "none", color:"white"}}>
                    <div className="grid" style={{alignItems: "center", border:"1px solid gray", backgroundColor:"#ac1c27", marginBottom:20, ...getKachelStyle()}}>
                        <div className="col" style={{justifyContent: "left", alignItems: "center", display:"flex"}}>
                            <SportsVolleyballIcon style={{fontSize:50, marginRight:20, color:"white"}}/><span style={{fontSize: isSmartphone() ? 20 : 30}}>Turnier-/ Ligaspiel {isDesktop && <span style={{marginLeft:20}}>(F)4</span>}</span>
                        </div>
                    </div>
                </NavLink>
                <NavLink to={NAVIGATION_ITEM.SETTINGS.route} style={{textDecoration: "none", color:"white"}}>
                    <div className="grid" style={{alignItems: "center", border:"1px solid gray", backgroundColor:"#ac1c27", marginBottom:20, ...getKachelStyle()}}>
                            <div className="col" style={{justifyContent: "left", alignItems: "center", display:"flex"}}>
                                <SettingsOutlinedIcon style={{fontSize:50, marginRight:20, color:"white"}}/><span style={{fontSize: isSmartphone() ? 20 : 30}}>Einstellungen {isDesktop && <span style={{marginLeft:20}}>(F)5</span>}</span>
                            </div>
                    </div>
                </NavLink>
                <div className="grid" style={{alignItems: "center", border:"1px solid gray", backgroundColor:"#ac1c27", marginBottom:20, ...getKachelStyle()}} onClick={() => openLiveViewControl()}>
                    <div className="col" style={{justifyContent: "left", alignItems: "center", display:"flex"}}>
                        <TvIcon style={{fontSize:50, marginRight:20, color:"white"}}/><span style={{fontSize: isSmartphone() ? 20 : 30}}>Live-View {isDesktop && <span style={{marginLeft:20}}>(F)9</span>}</span>
                    </div>
                </div>
                <div style={{color:"white", fontSize:12}}>
                    <a href="https://darthelfer.de/#/impressum" target="_self" style={{textDecoration:"none", color:"white"}}>--- Impressum ---</a>
                </div>
            </div>
            {!isSmartphone() && !isTabletHochformat(dimensions) &&
                <div className="col-12 md:col-12 lg:col-6" style={{textAlign: "center"}}>
                    <a href="https://www.darthelfer.de" target="_self">
                        <img src="/images/darthelfer_border_klein.png" alt="darthelfer.de"
                             title={"scorer.darthelfer.de - " + version} style={{width: 400}}/>
                    </a>
                </div>
            }
        </div>
        {showLiveViewControlDialog && <LiveViewControlDialog onCancel={() => setShowLiveViewControlDialog(false)}/>}
    </div>
}

export default Dashboard;
