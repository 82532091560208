import React, {useState} from "react";
import {makeStyles} from "@mui/styles";

import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import BarChartIcon from '@mui/icons-material/BarChart';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';

import {getFromLocalStorage, saveToLocalStorage} from "../localStorageZugriff";
import {Button, ThemeProvider} from "@mui/material";
import {CustomFontTheme} from "./CustomFontTheme";
import SettingsIcon from '@mui/icons-material/Settings';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import UndoOutlined from "@mui/icons-material/UndoOutlined";

const useStyles = makeStyles({
    row: {
        width: '100%',
        padding: 0,
        margin: 0,
    },
    column: {
        display: "table-cell",
        textAlign: "center",
        verticalAlign: "middle",
        border: "1px solid #064d6b",
        color: "white",
        fontSize: 20,
        fontWeight: "bold",
        backgroundColor:"#056f9a",
    },
});

function MyColumn ({value, action}) {
    const classes = useStyles();

    let [showClicked, setShowClicked] = useState(false);

    return <div className={"col " + classes.column} style={{backgroundColor: showClicked ? "#037caf" : "#056f9a", verticalAlign:"middle", display:"flex", justifyContent:"center", alignItems:"center"}}  onClick={() => {
        setShowClicked(true);
        setTimeout(() => {
            setShowClicked(false);
        }, 200);
        action(value);
    }}>
        {value}
    </div>
}

function MyIconColumn ({icon, action}) {
    const classes = useStyles();

    let [showClicked, setShowClicked] = useState(false);

    return <div className={"col " + classes.column} style={{backgroundColor: showClicked ? "#037caf" : "#056f9a", verticalAlign:"middle", display:"flex", justifyContent:"center", alignItems:"center"}}  onClick={() => {
        setShowClicked(true);
        setTimeout(() => {
            setShowClicked(false);
        }, 200);
        action();
    }}>
        {icon}
    </div>
}

export default function SmartphoneTastatur({selectedValue, showTableScoreValue, showFastScoreButtons, leftScoreAktiv, showUndo, firstAktive, secondAktive, thirdAktive, gameAbgeschlossen, showStatistic, onFinishLeg, onSaveLeftScore, onStatisticChange, onValueAdd, onShortCut, onRemoveValue, onEnter, onShowEinstellungenDialog, onChangeToDashboard, onUndo}) {

    const classes = useStyles();

    const [taste1] = useState(getFromLocalStorage("dh_default_x01_taste1"));
    const [taste2] = useState(getFromLocalStorage("dh_default_x01_taste2"));
    const [taste3] = useState(getFromLocalStorage("dh_default_x01_taste3"));
    const [taste4] = useState(getFromLocalStorage("dh_default_x01_taste4"));
    const [taste5] = useState(getFromLocalStorage("dh_default_x01_taste5"));
    const [taste6] = useState(getFromLocalStorage("dh_default_x01_taste6"));
    const [taste7] = useState(getFromLocalStorage("dh_default_x01_taste7"));
    const [taste8] = useState(getFromLocalStorage("dh_default_x01_taste8"));

    let [showTastatur, setShowTastatur] = useState(true);
    let [showDetails, setShowDetails] = useState(false);

    const [rowHeight, setRowHeight] = useState(getFromLocalStorage("dh_tablet_tastatur_row_height"));

    const addRestColumn = (value, action, active) => {
        if( active ) {
            return <div className={"col " + classes.column} style={{borderBottom:"3px solid white"}} onClick={() => action()}>
                {value}
            </div>
        }
        return <div className={"col " + classes.column} style={{borderBottom:"3px solid white", backgroundColor:"gray"}}>
            {value}
        </div>
    }

    if( !showTastatur) {
        return <div style={{position: "fixed", right: 5, bottom: 5}}>
            <ThemeProvider theme={CustomFontTheme}>
                <Button variant="text" startIcon={<CancelPresentationIcon style={{fontSize:30, color:"white"}} onClick={() => {
                    setShowTastatur(true);
                }}/>}/>
            </ThemeProvider>
        </div>
    }

    if( showStatistic ) {
        return <div style={{position: "fixed", right: 5, bottom: 5}}>
            <ThemeProvider theme={CustomFontTheme}>
                <Button variant="text" startIcon={<BarChartIcon style={{fontSize:30, color:"white"}} onClick={() => {
                    onStatisticChange();
                }}/>}/>
            </ThemeProvider>
        </div>
    }

    return <div style={{minHeight: "300", height: "300", width:"100%", margin:0}}>
        {showTableScoreValue && <div style={{position:"absolute", top:-180, right:0, width:300, height:180, textAlign:"center", backgroundColor:"yellow", fontSize:150}}>{selectedValue}</div> }
        <div className="grid" style={{padding:0, margin:0}}>
            <ThemeProvider theme={CustomFontTheme}>
                <Button variant="text" startIcon={<ExitToAppOutlinedIcon style={{fontSize: 30}}/>}
                        onClick={() => onChangeToDashboard()}/>
            </ThemeProvider>
            {!gameAbgeschlossen &&
                <ThemeProvider theme={CustomFontTheme}>
                    <Button variant="text" startIcon={<SettingsOutlinedIcon style={{fontSize: 30}}/>}
                            onClick={() => onShowEinstellungenDialog()}/>
                </ThemeProvider>
            }
            <ThemeProvider theme={CustomFontTheme}>
                <Button variant="text" startIcon={<CancelPresentationIcon style={{fontSize:30}}/>}
                        onClick={() => setShowTastatur(!showTastatur)}/>
            </ThemeProvider>
            {showUndo &&
                <ThemeProvider theme={CustomFontTheme}>
                    <Button variant="text" startIcon={<UndoOutlined style={{fontSize:30}}/>}
                            onClick={() => onUndo()}/>
                </ThemeProvider>
            }
        </div>
        <div className="grid" style={{padding:0, margin:0}}>
            {showFastScoreButtons && <>
                {addRestColumn("Rest", () => onSaveLeftScore(), leftScoreAktiv)}
                {addRestColumn("1st", () => onFinishLeg(1), firstAktive)}
                {addRestColumn("2nd", () => onFinishLeg(2), secondAktive)}
                {addRestColumn("3rd", () => onFinishLeg(3), thirdAktive)}
            </>
            }
        </div>
        <div className="grid" style={{padding:0, margin:0}}>
            {showDetails &&
                <div className="col" style={{padding:0, borderRight:"3px solid white"}}>
                    <div className={"grid " + classes.row} style={{height: "33%"}}>
                        <MyIconColumn icon={<ZoomOutIcon style={{fontSize: 30}}/>} action={() => {
                            saveToLocalStorage("dh_tablet_tastatur_row_height", rowHeight - 5);
                            setRowHeight(rowHeight - 5);
                        }}/>
                        <MyIconColumn icon={<ZoomInIcon style={{fontSize: 30}}/>} action={() => {
                            saveToLocalStorage("dh_tablet_tastatur_row_height", rowHeight + 5);
                            setRowHeight(rowHeight + 5);
                        }}/>
                    </div>
                    <div className={"grid " + classes.row} style={{height: "33%"}}>
                        <MyIconColumn icon={<BarChartIcon style={{fontSize: 20}}/>} action={() => {
                            onStatisticChange();
                        }}/>
                    </div>
                    <div className={"grid " + classes.row} style={{height: "33%"}}>
                        <MyIconColumn icon={<SettingsIcon style={{fontSize: 20}}/>} action={() => { setShowDetails(false)}}/>
                    </div>
                </div>
            }
            {!showDetails &&
                <div className="col" style={{padding:0, borderRight:"3px solid white"}}>
                    <div className={"grid " + classes.row} style={{height: rowHeight}}>
                        <MyIconColumn icon={<SettingsIcon style={{fontSize: 20}}/>} action={() => { setShowDetails(true)}}/>
                        <MyIconColumn icon={<ArrowBackIcon style={{fontSize: 20}}/>} action={onRemoveValue}/>
                    </div>
                    <div className={"grid " + classes.row} style={{height: rowHeight}}>
                        <MyColumn value={taste7} action={onShortCut}/>
                        <MyColumn value={taste8} action={onShortCut}/>
                    </div>
                    <div className={"grid " + classes.row} style={{height: rowHeight}}>
                        <MyColumn value={taste4} action={onShortCut}/>
                        <MyColumn value={taste5} action={onShortCut}/>
                        <MyColumn value={taste6} action={onShortCut}/>
                    </div>
                    <div className={"grid " + classes.row} style={{height: rowHeight}}>
                        <MyColumn value={taste1} action={onShortCut}/>
                        <MyColumn value={taste2} action={onShortCut}/>
                        <MyColumn value={taste3} action={onShortCut}/>
                    </div>
                </div>
            }
            <div className="col" style={{padding:0, backgroundColor:"#022836"}}>
                <div className={"grid " + classes.row} style={{height:rowHeight}}>
                    <MyColumn value="7" action={onValueAdd}/>
                    <MyColumn value="8" action={onValueAdd}/>
                    <MyColumn value="9" action={onValueAdd}/>
                </div>
                <div className={"grid " + classes.row} style={{height:rowHeight}}>
                    <MyColumn value="4" action={onValueAdd}/>
                    <MyColumn value="5" action={onValueAdd}/>
                    <MyColumn value="6" action={onValueAdd}/>
                </div>
                <div className={"grid " + classes.row} style={{height:rowHeight}}>
                    <MyColumn value="1" action={onValueAdd}/>
                    <MyColumn value="2" action={onValueAdd}/>
                    <MyColumn value="3" action={onValueAdd}/>
                </div>
                <div className={"grid " + classes.row} style={{height:rowHeight}}>
                    <MyColumn value="0" action={onValueAdd}/>
                    <MyIconColumn icon={<CheckIcon style={{fontSize:30}}/>} action={onEnter}/>
                </div>
            </div>
        </div>
    </div>
}
