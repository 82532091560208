import React, {useCallback, useEffect, useState} from "react";
import {Alert, Button, ThemeProvider} from "@mui/material";
import {CustomFontTheme} from "../x01/CustomFontTheme";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import {NAVIGATION_ITEM} from "../constants/navigationItems";
import {useSearchParams} from "react-router-dom";
import FetchUtil from "../util/FetchUtil";
import {getFromLocalStorage, saveToLocalStorage} from "../localStorageZugriff";

const getURL = (url, id) => {
    if( url.includes("localhost")) {
        return "http://localhost:8080/api/public/boardconnect/" + id;
    } else if( url.includes("test.darthelfer")) {
        return "https://test.darthelfer.de/api/public/boardconnect/" + id;
    } else  {
        return "https://www.darthelfer.de/api/public/boardconnect/" + id;
    }
}

export default function BoardConnectView() {

    const URL = getFromLocalStorage("dh_url");

    const [errorMessage, setErrorMessage] = useState(undefined);
    const [boardInfos, setBoardInfos] = useState(undefined);

    const [searchParams] = useSearchParams();

    const searchBoard = useCallback(() => {
        if( searchParams.get("id") === null || searchParams.get("id") === undefined) {
            return;
        }

        FetchUtil.fetchPost(getURL(URL, searchParams.get("id")), "", "",
          {},
          (json) => {
              if (json === null || json === undefined || json === "") {
                  setErrorMessage("Board konnte nicht ermittelt werden. Aktualisiere deinen QR Code und versuche es erneut.");
              } else {
                  setBoardInfos(json);
                  saveToLocalStorage("dh_token", json.token)
                  saveToLocalStorage("dh_board_token", json.boardToken)
                  saveToLocalStorage("dh_board_bezeichnung", json.boardBezeichnung)
              }
          },
          (responseNotOk) => {
              console.log(responseNotOk);
              setErrorMessage(responseNotOk.message);
          },
          (error) => {
              console.log(error);
              setErrorMessage(error.message);
          });
    }, [URL, searchParams]);

    useEffect(() => {
        searchBoard();
    }, [searchBoard, searchParams]);

    const changeToDashboard = () => {
        window.open(NAVIGATION_ITEM.DASHBOARD.route, "_self");
    }

    return <div style={{backgroundColor: "#022836", minHeight: "100vh", height: "100%"}}>
        <div style={{position: "absolute", bottom: 5, left: 0, width: 70}}>
            <ThemeProvider theme={CustomFontTheme}>
                <Button variant="text" startIcon={<ExitToAppOutlinedIcon style={{fontSize: 50}}/>}
                        onClick={() => changeToDashboard()}/>
            </ThemeProvider>
        </div>
        {errorMessage !== undefined && <Alert severity="error" style={{fontSize: 25}}>
            {errorMessage}
        </Alert>
        }
        {boardInfos !== undefined && <div style={{textAlign: "center", color: "white"}}>
            <div style={{padding: 20, fontSize: 20}}>Wir haben folgendes Board eingerichtet:</div>
            <div style={{padding: 20, fontSize: 40, color:"red"}}>{boardInfos.boardBezeichnung}</div>
            <div style={{padding: 20, fontSize: 20}}>
                Bitte gehe unter<br/>Einstellungen-&gt;DH-Verbindung<br/>
                und wähle evtl. das von dir gewünschte Board aus. (z.B. Board 5 o. Board 6)
            </div>
        </div>
        }
    </div>
}
