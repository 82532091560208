import {Alert, Button, CircularProgress, Snackbar, ThemeProvider} from "@mui/material";
import {CustomFontTheme} from "../x01/CustomFontTheme";
import {NAVIGATION_ITEM} from "../constants/navigationItems";
import React, {useCallback, useEffect, useState} from 'react';
import FetchUtil from "../util/FetchUtil";
import {getFromLocalStorage, saveToLocalStorage} from "../localStorageZugriff";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import moment from "moment";
import {newPlayerWithId} from "../x01/GameHelper";
import {getToGo} from "../x01/ScoreHelper";
import {isSmartphone} from "../util/GeraeteArtUtil";
import {GameStartArea} from "../x01/GameStartArea";

function TournamentOverview() {

    const smartphone = isSmartphone();

    const defaultTimerCounter = 60;

    const [showGameStartDialog, setShowGameStartDialog] = useState(false);

    const [game, setGame] = useState(undefined);

    const [errorMessage, setErrorMessage] = useState(undefined);
    const [noGamesCounter, setNoGamesCounter] = useState(0);
    const [timerCounter, setTimerCounter] = useState(6);
    const [loading, setLoading] = useState(false);
    const url = getFromLocalStorage("dh_url");
    const token = getFromLocalStorage("dh_token");
    const boardToken = getFromLocalStorage("dh_board_token");
    const boardBezeichnung = getFromLocalStorage("dh_board_bezeichnung");
    const [configOK] = useState(url !== undefined && url !== "" && token !== undefined && token !== "" && boardToken !== undefined && boardToken !== "");

    useEffect(() => {
        window.addEventListener("keydown", onKeyDown);

        return () => window.removeEventListener("keydown", onKeyDown);
    }, []);

    useEffect(() => {
        if( noGamesCounter >= 60) {
            window.open(NAVIGATION_ITEM.DASHBOARD.route, "_self");
        }
    }, [noGamesCounter]);


    const sendGameShortInfoToBackend = useCallback((game) => {
        if (game.turnierId === null || game.turnierId === undefined) {
            // nur Turnierspiele werden an den Server geschickt.
            return;
        }
        if (!game.publishTournament) {
            // nur wenn Spiel veröffentlicht wird.
            return;
        }
        let players = [];
        players.push({
            playerId: game.players[0].id,
            name: game.players[0].name,
            legAnwurf: true,
            active: true,
            lastThrow: undefined,
            toGo: getToGo(game, 0),
            gewonneneSets: 0,
            gewonneneLegs: 0,
            average: "",
            best: undefined
        });
        players.push({
            playerId: game.players[1].id,
            name: game.players[1].name,
            legAnwurf: false,
            active: false,
            lastThrow: undefined,
            toGo: getToGo(game, 1),
            gewonneneSets: 0,
            gewonneneLegs: 0,
            average: "",
            best: undefined
        });
        let data = {
            gameId: game.id,
            tournamentId: game.turnierId,
            modusId: game.modusId,
            startpunkte: Number(game.startpunkte),
            sets: Number(game.sets),
            bestOfLegs: game.bestoflegs !== undefined ? Number(game.bestoflegs) : undefined,
            firstToLegs: game.firsttolegs !== undefined ? Number(game.firsttolegs) : undefined,
            boardBezeichnung: game.boardBezeichnung,
            boardId: game.boardId,
            schreiberId: game.schreiberId,
            schreiberName: game.schreiberName,
            dartType: game.dartType,
            gewinnerPlayerId: undefined,
            startssoon: game.startssoon,
            inGame: game.inGame,
            dhGameShortPlayerInfoDtos: players
        };
        FetchUtil.fetchPost(url + "/saveShortInfo", token, boardToken,
          data,
          () => {
              console.log("game wurde zum backend uebertragen: " + game.id);
          },
          responseNotOk => {
              // nix tun, es gibt Probleme aber an der Stelle unwichtig
              console.log("Fehler: " + responseNotOk.message);
          },
          error => {
              // nix tun, es gibt Probleme aber an der Stelle unwichtig
              console.log("Fehler: " + error.message);
          });
    }, [url, token, boardToken]);

    const createGame = useCallback((json) => {
        let gameFromLocalStorage = getFromLocalStorage("dh_game_" + json.gameid);
        if (gameFromLocalStorage !== undefined) {
            // Lade Game aus LocalStorage
            let game = {...gameFromLocalStorage,
                schreiberId: json.schreiberId,
                schreiberName: json.schreiberName,
                mannschaftPlayer1: json.mannschaftPlayer1,
                mannschaftPlayer2: json.mannschaftPlayer2,
                dartType: json.dartType,
                boardBezeichnung: json.boardBezeichnung,
                sets: json.sets,
                firsttolegs: json.firstToLegs !== null ? "" + json.firstToLegs : undefined,
                bestoflegs: json.bestOfLegs !== null ? "" + json.bestOfLegs : undefined,
                startpunkte: "" + json.startpunkte,
                modusId: json.modusId,
                publishTournament: json.publishTournament,
                startssoon: true,
                inGame: false,
                tournamentBezeichnung: json.tournamentBezeichnung};
            // spieler in einem Game kann sich im Ligaspieltag nachträglich ändern.
            game.players[0].id = json.players[0].playerid
            game.players[0].name = json.players[0].playername
            game.players[1].id = json.players[1].playerid
            game.players[1].name = json.players[1].playername
            sendGameShortInfoToBackend(game);
            setGame(game);
            setShowGameStartDialog(true);
            return;
        }

        let players = [];
        let playerCounter = 1;
        let scoreCounter = 1;
        for (const player of json.players) {
            players.push(newPlayerWithId(player.playerid, player.playername, playerCounter, 1, 1, scoreCounter));
            playerCounter++;
            scoreCounter++;
        }

        let game = {
            id: json.gameid,
            turnierId: json.turnierId,
            turnierGameId: json.turnierGameId,
            boardId: json.boardId,
            boardBezeichnung: json.boardBezeichnung,
            schreiberId: json.schreiberId,
            schreiberName: json.schreiberName,
            mannschaftPlayer1: json.mannschaftPlayer1,
            mannschaftPlayer2: json.mannschaftPlayer2,
            dartType: json.dartType,
            startssoon: true,
            inGame: false,
            sets: json.sets,
            firsttolegs: json.firstToLegs !== null ? "" + json.firstToLegs : undefined,
            bestoflegs: json.bestOfLegs !== null ? "" + json.bestOfLegs : undefined,
            startpunkte: "" + json.startpunkte,
            modusId: json.modusId,
            publishTournament: json.publishTournament,
            tournamentBezeichnung: json.tournamentBezeichnung,
            timestamp: moment().format(),
            players: players,
        };
        setGame(game);
        sendGameShortInfoToBackend(game);
        setShowGameStartDialog(true);
    }, [sendGameShortInfoToBackend]);

    useEffect(() => {
        if (!configOK) {
            return;
        }
        setTimeout(() => {
            // zur Sicherheit, sofern der Store evtl. mit dem Schreiben etwas braucht.
            setTimerCounter(timerCounter - 1);
            if (timerCounter <= 0) {
                setLoading(true);
                setTimerCounter(defaultTimerCounter);
                setErrorMessage(undefined);
                FetchUtil.fetchGet(url + "/games", token, boardToken,
                    json => {
                        if (json !== null && json !== undefined && json !== "") {
                            createGame(json);
                            setNoGamesCounter(0);
                        } else {
                            setNoGamesCounter(noGamesCounter + 1);
                        }
                        setLoading(false);
                    },
                    responseNotOk => {
                        setErrorMessage("Fehler: " + responseNotOk.message)
                        setLoading(false);
                    },
                    error => {
                        setErrorMessage("Fehler: " + error.message)
                        setLoading(false);
                    });
            }
        }, 1000);
    }, [url, token, boardToken, timerCounter, configOK, noGamesCounter, createGame]);

    const showGame = () => {
        game.inGame = true;
        game.startssoon = false;
        sendGameShortInfoToBackend(game);
        let gameKey = "dh_game_" + game.id;
        saveToLocalStorage(gameKey, game);
        setShowGameStartDialog(false);
        if( game.dartType === "EDART") {
            window.open(NAVIGATION_ITEM.EDARTGAME.route + "?id=dh_game_" + game.id, "_self");
        } else {
            window.open(NAVIGATION_ITEM.GAME.route + "?id=dh_game_" + game.id, "_self");
        }
    }

    const onKeyDown = (event) => {
        switch (event.key) {
            case "Escape":
            case "*":
                window.open(NAVIGATION_ITEM.DASHBOARD.route, "_self");
                event.preventDefault();
                break;
            case "F1":
            case "F2":
            case "F3":
            case "F4":
            case "F6":
            case "F7":
            case "F8":
            case "F9":
            case "F10":
            case "F11":
            case "F12":
                // F Tasten werden damit abgeschalten. F5 für Refresh wird zum Browser durchgelassen
                event.preventDefault();
                break;
            default:
                break;
        }
        event.stopPropagation();
    };

    if( showGameStartDialog ) {
        return <GameStartArea onClose={() => showGame()}
                       player1Name={game.players[0].name}
                       player2Name={game.players[1].name}
                       modusId={game.modusId}
                       startpunkte={game.startpunkte}
                       sets={game.sets}
                       refreshCounter={timerCounter}
                       bestOfLegs={game.bestoflegs !== undefined ? Number(game.bestoflegs) : undefined}
                       firstToLegs={game.firsttolegs !== undefined ? Number(game.firsttolegs) : undefined}
                       boardBezeichnung={game.boardBezeichnung}
                       tournamentBezeichnung={game.tournamentBezeichnung}
                       schreiberName={game.schreiberName}
                              mannschaftPlayer1={game.mannschaftPlayer1}
                              mannschaftPlayer2={game.mannschaftPlayer2}
        />
    }

    return <div style={{padding: 20, backgroundColor: "#022836", minHeight: "100vh", height: "100%"}}>
        <Snackbar open={errorMessage !== undefined} autoHideDuration={3000} onClose={() => setErrorMessage(undefined)}
                  anchorOrigin={{vertical: "top", horizontal: "center"}}>
            <Alert onClose={() => setErrorMessage(undefined)} severity="error" sx={{width: '100%', fontSize: smartphone? 20 : 30}}>
                {errorMessage}
            </Alert>
        </Snackbar>
        <div style={{position: "fixed", left: 5, top: 5}}>
            <ThemeProvider theme={CustomFontTheme}>
                <Button variant="text" style={{color: "white"}}
                        startIcon={<ExitToAppOutlinedIcon style={{fontSize: 50, color: "white"}}/>}
                        onClick={() => window.open(NAVIGATION_ITEM.DASHBOARD.route, "_self")}/>
            </ThemeProvider>
        </div>
        <div className="grid">
            <div className="col" style={{textAlign: "center", color: "white", fontSize: smartphone ? 25 : 30}}>
                {boardBezeichnung !== undefined && boardBezeichnung !== "" &&
                    <div style={{fontSize: smartphone ? 30: 40, marginTop:30, marginBottom: 20}}>{boardBezeichnung}</div>}
                <img src="/images/darthelfer_border_klein.png" alt="darthelfer.de" title={"scorer.darthelfer.de"}
                     style={{width: smartphone ? 200 : 400}}/>
                <br/>
                {!configOK && <div>Dein Zugang zu Darthelfer ist nicht konfiguriert!!!</div>}
                {configOK && !loading && <div>Suche nach Spiel... {timerCounter}</div>}
                {loading && <CircularProgress style={{width: 50}}/>}
            </div>
        </div>
    </div>
}

export default TournamentOverview;
