import {useCallback, useEffect} from 'react';
import {Button, Dialog} from "@mui/material";

export function X01EinstellungenDialog({smartphone, onClose, onPlayerScoreChange}) {

    const onKeyDown = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
        switch (event.key) {
            case "Escape":
                onClose();
                break;
            case "1":
                onPlayerScoreChange();
                break;
            default:
                break;
        }
    }, [onClose, onPlayerScoreChange]);

    useEffect(() => {
        window.addEventListener("keydown", onKeyDown);
        return () => {
            window.removeEventListener("keydown", onKeyDown);
        }
    }, [onKeyDown]);

    return <Dialog
        sx={{'& .MuiDialog-paper': {minWidth: "100%", height: "100%", backgroundColor:"#ac1c27", zIndex:20000}}}
        open={true} disableEscapeKeyDown={true}>
        <div style={{marginLeft:20, color:"white"}}>
            <h2>Allgemeine Einstellungen</h2>
        </div>
        <div style={{textAlign:"left"}}>
            <Button style={{color:"white", fontSize:smartphone ? 16: 24}} variant="text" onClick={() => onPlayerScoreChange()}>Klicke hier oder 1<br/>
                sobald du alle Scores <br/>von Spieler 1 auf Spieler 2<br/> umdrehen möchtest.</Button>
        </div>
        <div>
            <Button style={{color:"white", fontSize:smartphone ? 16: 24}} variant="text" onClick={() => onClose()}>Abbrechen {!smartphone && "(ESC)"}</Button>
        </div>
    </Dialog>
}
