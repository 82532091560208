import {useCallback, useEffect, useState} from "react";
import {NAVIGATION_ITEM} from "../constants/navigationItems";
import {Alert, Button, TextField, ThemeProvider} from "@mui/material";
import {GameModusComponent} from "./GameModusComponent";
import {CustomFontNewDialogTheme} from "./CustomFontNewDialogTheme";
import {StartpunkteComponent} from "./StartpunkteComponent";
import {v4 as uuidv4} from 'uuid';
import {newPlayer} from "./GameHelper";
import moment from "moment/moment";
import {getFromLocalStorage} from "../localStorageZugriff";

import {isDesktop, isSmartphone} from "../util/GeraeteArtUtil";

function NewGameArea({onSave}) {

    const SETBESTOFRRORMESSAGE = "Bei Sets > 1 muss best of legs eine ungerade Zahl z.B. 1,3,5,7... enthalten.";

    //const [playerCount, setPlayerCount] = useState("2");
    const playerCount = "2";
    const [player1, setPlayer1] = useState(getFromLocalStorage("dh_default_player1"));
    const [player2, setPlayer2] = useState(getFromLocalStorage("dh_default_player2"));
    const [player3, setPlayer3] = useState("Spieler 3");
    const [player4, setPlayer4] = useState("Spieler 4");
    const [sets, setSets] = useState(getFromLocalStorage("dh_default_sets"));
    const [firsttolegs, setFirsttolegs] = useState(getFromLocalStorage("dh_default_firsttolegs"));
    const [bestoflegs, setBestoflegs] = useState(getFromLocalStorage("dh_default_bestoflegs"));
    const [startpunkte, setStartpunkte] = useState(getFromLocalStorage("dh_default_startpunkte"));
    const [modusId, setModusId] = useState(getFromLocalStorage("dh_default_modusid"));

    const [error, setError] = useState("");

    const clearMessages = () => {
        setError("");
    }

    const showError = (message) => {
        setError(message);
    }

    const isBestOfValid = useCallback(() => {
        if (sets > 1 && bestoflegs !== undefined && bestoflegs !== "") {
            return bestoflegs % 2 === 1;
        }
        return true;
    }, [bestoflegs, sets])

    const save = useCallback(() => {
        clearMessages();
        if (!isBestOfValid()) {
            showError(SETBESTOFRRORMESSAGE);
            return;
        }
        if (sets === "" || (firsttolegs === "" && bestoflegs === "")) {
            showError("sets und first to legs oder sets und best of legs muss gefüllt sein.");
            return;
        }
        if (firsttolegs !== "" && Number(firsttolegs) === 0) {
            showError("0 ist bei first to legs nicht erlaubt.");
            return;
        }
        if (firsttolegs !== "" && Number(firsttolegs) > 30) {
            showError("Grösser 30 ist bei first to legs nicht erlaubt.");
            return;
        }
        if (bestoflegs !== "" && Number(bestoflegs) === 0) {
            showError("0 ist bei best of legs nicht erlaubt.");
            return;
        }
        if (bestoflegs !== "" && Number(bestoflegs) > 30) {
            showError("Grösser 30 ist bei best of legs nicht erlaubt.");
            return;
        }
        if (playerCount === "" || Number(playerCount) < 2 || Number(playerCount) > 4) {
            showError("Anzahl Spieler muss einen Wert zwischen 2 und 4 haben.");
            return;
        }
        if (sets === "" || Number(sets) < 1 || Number(sets) > 20) {
            showError("Sets muss einen Wert zwischen 1 und 20 haben.");
            return;
        }
        if (Number(playerCount) > 2 && bestoflegs !== "") {
            showError("Bei mehr als 3 Spielern ist best of legs nicht erlaubt, bitte wähle first to legs.");
            return;
        }


        let players = [];
        players.push(newPlayer(player1 !== "" ? player1 : "Spieler 1", 1, 1, 1, 1));
        players.push(newPlayer(player2 !== "" ? player2 : "Spieler 2", 2, 1, 1, 2));
        if (Number(playerCount) > 2) {
            players.push(newPlayer(player3 !== "" ? player3 : "Spieler 3", 3, 1, 1, 3));
        }
        if (Number(playerCount) > 3) {
            players.push(newPlayer(player4 !== "" ? player4 : "Spieler 4", 4, 1, 1, 4));
        }
        let game = {
            id: uuidv4(),
            sets: sets,
            firsttolegs: firsttolegs,
            bestoflegs: bestoflegs,
            startpunkte: startpunkte,
            modusId: modusId,
            timestamp: moment().format(),
            players: players,
        };
        onSave(game);
    }, [bestoflegs, firsttolegs, isBestOfValid, modusId, onSave, player1, player2, player3, player4, playerCount, sets, startpunkte])

    const onKeyDown = useCallback((event) => {
        switch (event.key) {
            case "Escape":
            case "*":
                window.open(NAVIGATION_ITEM.DASHBOARD.route, "_self");
                event.preventDefault();
                break;
            case "Enter":
                save();
                event.preventDefault();
                break;
            case "F1":
            case "F2":
            case "F3":
            case "F4":
            case "F5":
            case "F6":
            case "F7":
            case "F8":
            case "F9":
            case "F10":
            case "F11":
            case "F12":
                // F Tasten werden damit abgeschalten.
                event.preventDefault();
                break;
            default:
                break;
        }
        event.stopPropagation();
    }, [save]);

    useEffect(() => {
        window.addEventListener("keydown", onKeyDown);
        return () => {
            window.removeEventListener("keydown", onKeyDown);
        }
    }, [onKeyDown]);

    const getLabelFontSize = () => {
        if( isSmartphone()) {
            return "4.0vw";
        } else {
            return "2.0vw";
        }
    }

    const getTextFieldFontSize = () => {
        if( isSmartphone()) {
            return "6.0vw";
        } else {
            return "3.0vw";
        }
    }

    return <div style={{height: "100%", minHeight: "100vh", width: "100%", backgroundColor:"#022836", padding:10}}>
        {error !== "" && <Alert severity="error" style={{fontSize: 25}}>
            {error}
        </Alert>
        }
        <div className="grid" style={{margin: 0, padding: 20}}>
            <div className="col-12 md:col-12 lg:col-6" style={{border:"1px solid gray", backgroundColor:"#ac1c27", padding: 20}}>
                <div style={{marginBottom: 20, fontSize: getLabelFontSize(), color:"white"}}>
                    <div>Name Spieler 1</div>
                    <div>
                        <ThemeProvider theme={CustomFontNewDialogTheme}>
                            <TextField id="standard-player1" sx={{input: {color: '#b6b6b6', fontSize: getTextFieldFontSize()}}}
                                       style={{width: "100%"}}
                                                                variant="standard" value={player1}
                                                                onChange={(e) => setPlayer1(e.target.value)}/>
                        </ThemeProvider>
                    </div>
                </div>
                <div style={{marginBottom: 20, fontSize: getLabelFontSize(), color:"white"}}>
                    <div>Name Spieler 2</div>
                    <div>
                        <ThemeProvider theme={CustomFontNewDialogTheme}>
                            <TextField id="standard-player2" sx={{input: {color: '#b6b6b6', fontSize: getTextFieldFontSize()}}}
                                       style={{width: "100%"}}
                                       variant="standard" value={player2}
                                       onChange={(e) => setPlayer2(e.target.value)}/>
                        </ThemeProvider>
                    </div>
                </div>
                {playerCount !== "" && Number(playerCount) > 2 &&
                    <div style={{marginBottom: 20, fontSize: getLabelFontSize(), color:"white"}}>
                        <div>Name Spieler 3</div>
                        <div>
                            <ThemeProvider theme={CustomFontNewDialogTheme}>
                                <TextField id="standard-player3" sx={{input: {color: '#b6b6b6', fontSize: getTextFieldFontSize()}}}
                                           style={{width: "100%"}}
                                           variant="standard" value={player3}
                                           onChange={(e) => setPlayer3(e.target.value)}/>
                            </ThemeProvider>
                        </div>
                    </div>
                }
                {playerCount !== "" && Number(playerCount) > 3 &&
                    <div style={{marginBottom: 20, fontSize: getLabelFontSize(), color:"white"}}>
                        <div>Name Spieler 4</div>
                        <div>
                            <ThemeProvider theme={CustomFontNewDialogTheme}>
                                <TextField id="standard-player4" sx={{input: {color: '#b6b6b6', fontSize: getTextFieldFontSize()}}}
                                           style={{width: "100%"}}
                                           variant="standard" value={player4}
                                           onChange={(e) => setPlayer4(e.target.value)}/>
                            </ThemeProvider>
                        </div>
                    </div>
                }
            </div>
            <div className="col-12 md:col-12 lg:col-3" style={{border:"1px solid gray", backgroundColor:"#ac1c27", padding: 20}}>
                <div style={{marginBottom: 20, fontSize: getLabelFontSize(), color:"white"}}>
                    <div>Sets</div>
                    <div>
                        <ThemeProvider theme={CustomFontNewDialogTheme}>
                            <TextField id="standard-sets" sx={{input: {color: '#b6b6b6', fontSize: getTextFieldFontSize()}}}
                                       variant="standard" value={sets}
                                       onChange={(e) => setSets(e.target.value)}/>
                        </ThemeProvider>
                    </div>
                </div>
                <div style={{marginBottom: 20, fontSize: getLabelFontSize(), color:"white"}}>
                    <div>first to legs</div>
                    <div>
                        <ThemeProvider theme={CustomFontNewDialogTheme}>
                            <TextField id="standard-firsttolegs" sx={{input: {color: '#b6b6b6', fontSize: getTextFieldFontSize()}}}
                                       variant="standard" value={firsttolegs}
                                       onChange={(e) => {
                                           setFirsttolegs(e.target.value);
                                           setBestoflegs("");
                                       }}/>
                        </ThemeProvider>
                    </div>
                </div>
                <div style={{marginBottom: 20, fontSize: getLabelFontSize(), color:"white"}}>
                    <div>best of legs</div>
                    <div>
                        <ThemeProvider theme={CustomFontNewDialogTheme}>
                            <TextField id="standard-bestoflegs" sx={{input: {color: '#b6b6b6', fontSize: getTextFieldFontSize()}}}
                                       variant="standard" value={bestoflegs}
                                       onChange={(e) => {
                                           setBestoflegs(e.target.value);
                                           setFirsttolegs("");
                                       }}/>
                        </ThemeProvider>
                    </div>
                </div>
            </div>
            <div className="col-12 md:col-12 lg:col-3" style={{border:"1px solid gray", backgroundColor:"#ac1c27", padding: 20}}>
                <div style={{marginBottom: 20, fontSize: getLabelFontSize(), color:"white"}}>
                    <div>Startpunkte</div>
                    <div>
                        <StartpunkteComponent startpunkte={startpunkte}
                                              onStartpunkteChanged={(value) => setStartpunkte(value)}
                                              theme={CustomFontNewDialogTheme}
                                                fontSize={getTextFieldFontSize()}/>
                    </div>
                </div>
                <div style={{marginBottom: 20, fontSize: getLabelFontSize(), color:"white"}}>
                    <div>Modus</div>
                    <div>
                        <GameModusComponent modusId={modusId} onModusChanged={(value) => setModusId(value)}
                                            theme={CustomFontNewDialogTheme}
                                            fontSize={getTextFieldFontSize()}/>
                    </div>
                </div>
            </div>
        </div>
        <div style={{margin: 0, paddingLeft: 20}}>
            <ThemeProvider theme={CustomFontNewDialogTheme}>
                <Button variant="text" onClick={() => save()} style={{fontSize: getLabelFontSize(), marginRight:50}}>Spiel starten{isDesktop() && " (Enter)"}</Button>
                <Button variant="text"
                        onClick={() => window.open(NAVIGATION_ITEM.DASHBOARD.route, "_self")}  style={{fontSize: getLabelFontSize()}}>
                    Abbrechen{isDesktop() && " (Escape / *)"}</Button>
            </ThemeProvider>
        </div>
    </div>
}

export default NewGameArea;
