import {Button, Dialog, DialogActions, ThemeProvider} from "@mui/material";
import {CustomFontTheme} from "../x01/CustomFontTheme";
import {useEffect, useState} from "react";

export default function UpdateDialog({onUpdate}) {

    const [timerCounter, setTimerCounter] = useState(20);

    useEffect(() => {
        setTimeout(() => {
            if( timerCounter < 0) {
                return;
            }
            setTimerCounter(timerCounter - 1);
            if( timerCounter === 0) {
                onUpdate();
            }

        }, 1000);
    }, [timerCounter, onUpdate]);

    return <Dialog open={true} style={{backgroundColor:"white"}}>
        <div style={{padding:20, fontSize:30, backgroundColor:"#ac1c27", color:"white"}}>Es steht eine neue Version<br/> zum Download bereit?</div>
        <div style={{padding:20, fontSize:20, backgroundColor:"#ac1c27", color:"white"}}>Sollte diese Nachricht nach dem Update erneut angezeigt werden, starte bitte deinen Rechner neu.</div>
        <div style={{padding:20, fontSize:20, backgroundColor:"#ac1c27", color:"white"}}>Das Update wird in <span style={{fontWeight:"bold"}}>{timerCounter} Sekunden</span> automatisch durchgeführt.</div>
        <DialogActions style={{padding:20, backgroundColor:"#ac1c27"}}>
            <ThemeProvider theme={CustomFontTheme}>
                <Button variant="text" onClick={() => onUpdate()}>Update durchführen (U)</Button>
            </ThemeProvider>
        </DialogActions>
    </Dialog>
}
