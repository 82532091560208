import React, {useCallback, useEffect, useState} from 'react';
import {Button} from "@mui/material";
import {isDesktop, isSmartphone, isTabletHochformat, isTabletQuerformat} from "../util/GeraeteArtUtil";
import {getModusById} from "../constants/GameModusComponent";
import {getFromLocalStorage} from "../localStorageZugriff";

export function GameStartArea({player1Name, player2Name, schreiberName, boardBezeichnung,
                                  sets, bestOfLegs, firstToLegs, modusId, startpunkte,
                                  tournamentBezeichnung, mannschaftPlayer1, mannschaftPlayer2, refreshCounter, onClose}) {

    const autoscore = getFromLocalStorage("dh_autoscore");

    useEffect(() => {
       if( autoscore === undefined) {
           return;
       }
       onClose();
    }, [autoscore, onClose]);

    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    })

    const onKeyDown = useCallback((event) => {
        switch (event.key) {
            case "Enter":
                onClose();
                event.preventDefault();
                break;
            default:
                break;
        }
        event.stopPropagation();
    }, [onClose]);

    useEffect(() => {
        window.addEventListener("keydown", onKeyDown);
        return () => {
            window.removeEventListener("keydown", onKeyDown);
        }
    }, [onKeyDown]);

    useEffect(() => {
        function handleResize() {
            console.log("handle")
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const getPlayerFontSize = () =>  {
        if( isTabletQuerformat(dimensions)) {
            return "6.0vw";
        } else if( isTabletHochformat(dimensions)) {
            return "8.0vw";
        } else if( isSmartphone()) {
            return "8.0vw";
        } else {
            return 60;
        }
    }

    const getSchreiberFontSize = () =>  {
        if( isTabletQuerformat(dimensions)) {
            return "4.0vw";
        } else if( isTabletHochformat(dimensions)) {
            return "6.0vw";
        } else if( isSmartphone()) {
            return "7.0vw";
        } else {
            return 50;
        }
    }

    const getBoardFontSize = () =>  {
        if( isTabletQuerformat(dimensions)) {
            return "3.0vw";
        } else if( isTabletHochformat(dimensions)) {
            return "4.0vw";
        } else if( isSmartphone()) {
            return "5.0vw";
        } else {
            return 30;
        }
    }

    const getMannschaftFontSize = () =>  {
        if( isTabletQuerformat(dimensions)) {
            return "2.0vw";
        } else if( isTabletHochformat(dimensions)) {
            return "3.0vw";
        } else if( isSmartphone()) {
            return "4.0vw";
        } else {
            return 20;
        }
    }

    const getModusFontSize = () =>  {
        if( isTabletQuerformat(dimensions)) {
            return "2.0vw";
        } else if( isTabletHochformat(dimensions)) {
            return "3.0vw";
        } else if( isSmartphone()) {
            return "4.0vw";
        } else {
            return 20;
        }
    }

    const isMannschaftsTurnier = () => {
        return mannschaftPlayer1 !== null && mannschaftPlayer1 !== undefined && mannschaftPlayer1 !== "";
    }

    return <div style={{padding:20, color:"white", fontSize:40, textAlign:"center", backgroundColor: "#019CAD", backgroundImage: "linear-gradient(to top, #d35307, #019CAD)", height:"100%", minHeight:"100vh"}}  onClick={() => onClose()}>
            {isMannschaftsTurnier() &&
                <div style={{margin:0, marginBottom:0, fontSize:getMannschaftFontSize()}}>
                    {mannschaftPlayer1}
                </div>
            }
            <div style={{fontSize:getPlayerFontSize()}}>
                {player1Name}
            </div>
            <div style={{fontSize:"1.0vw"}}>
                vs.
            </div>
            {isMannschaftsTurnier() &&
                <div style={{marginTop:20, marginBottom:0, fontSize:getMannschaftFontSize()}}>
                    {mannschaftPlayer2}
                </div>
            }
            <div style={{fontSize:getPlayerFontSize()}}>
                {player2Name}
            </div>
            {schreiberName !== null && schreiberName !== undefined &&
                <div style={{marginTop:20, marginBottom:20, fontSize:getSchreiberFontSize()}}>
                    Schreiber: {schreiberName}
                </div>
            }
            {boardBezeichnung !== null && boardBezeichnung !== undefined && boardBezeichnung !== "" &&
                <div style={{marginTop:20, marginBottom:20, fontSize:getBoardFontSize()}}>
                    Board: {boardBezeichnung}
                </div>
            }
            {!isMannschaftsTurnier() && tournamentBezeichnung !== null && tournamentBezeichnung !== undefined && tournamentBezeichnung !== "" &&
                <div style={{marginTop:20, marginBottom:20, fontSize:getBoardFontSize()}}>
                    {tournamentBezeichnung}
                </div>
            }
            <div style={{marginTop:20, marginBottom:20, fontSize:getModusFontSize()}}>
                {sets > 1 && "set " + sets + " - "}{firstToLegs !== undefined && firstToLegs !== "" ? " first to " + firstToLegs + " legs" : ""}{bestOfLegs !== undefined && bestOfLegs !== "" ? "best of " + bestOfLegs + " legs" : ""} - {getModusById(modusId).bezeichnung} - {startpunkte}
            </div>

            <div style={{marginTop:10}}>
                <Button style={{color:"white", fontSize: isSmartphone() ? 30 : "3.0vw"}} variant="text" onClick={() => onClose()}>Zum Spiel {isDesktop() && "(ENTER)"}</Button>
            </div>
            <div style={{marginTop:0}}>
                <img src="/images/darthelfer_quer_weiss.png" alt="darthelfer.de" title="darthelfer.de"
                     style={{marginTop:10, width: isSmartphone() ? "90%" : 500}}/>
            </div>
            <div style={{marginTop:10, fontSize:12}}>
                -- Aktualisierung in {refreshCounter} Sekunden --
            </div>
    </div>
}
