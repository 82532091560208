import {useCallback, useEffect} from 'react';
import {Button, Dialog} from "@mui/material";

export function FinishDartsDialog({onClose, onSave}) {

    const onKeyDown = useCallback( (event) => {
        event.preventDefault();
        switch (event.key) {
            case "Escape":
            case "*":
                onClose();
                break;
            case "1":
                onSave(1);
                break;
            case "2":
                onSave(2);
                break;
            case "3":
                onSave(3);
                break;
            default:
                break;
        }
        event.stopPropagation();
    }, [onSave, onClose]);

    useEffect(() => {
        window.addEventListener("keydown", onKeyDown);
        return () => {
            window.removeEventListener("keydown", onKeyDown);
        }
    }, [onKeyDown]);

    return <Dialog
        sx={{'& .MuiDialog-paper': {minWidth: "100%", height: "100%", backgroundColor:"#ac1c27", zIndex:20000}}}
        open={true} disableEscapeKeyDown={true}>
        <div style={{marginLeft:20, color:"white", fontSize:24}}>
            <h2>Wieviele Darts hast du geworfen?</h2>
        </div>
        <div>
            <Button style={{color:"white", fontSize:40}} variant="text" onClick={() => onSave(1)}>1 Dart (1)</Button>
        </div>
        <div>
            <Button style={{color:"white", fontSize:40}} variant="text" onClick={() => onSave(2)}>2 Darts (2)</Button>
        </div>
        <div>
            <Button style={{color:"white", fontSize:40}} variant="text" onClick={() => onSave(3)}>3 Darts (3)</Button>
        </div>
        <div>
            <Button style={{color:"white", fontSize:40}} variant="text" onClick={() => onClose()}>Abbrechen (ESC oder *)</Button>
        </div>
    </Dialog>
}
