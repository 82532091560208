import React, {useCallback, useEffect, useState} from 'react';
import {Button, LinearProgress} from "@mui/material";
import {isDesktop, isSmartphone} from "../util/GeraeteArtUtil";
import {getFromLocalStorage} from "../localStorageZugriff";

export function GameUebermittelnArea({onClose, onUebermitteln, gewinner, unentschieden}) {

    let [waiting, setWaiting] = useState(false);
    let [waitingSeconds, setWaitingSeconds] = useState(getFromLocalStorage("dh_default_seconds_to_send_tournament_game"));

    const onKeyDown = useCallback((event) => {
        if( waiting ) {
            return;
        }
        switch (event.key) {
            case "Escape":
            case "*":
                onClose();
                event.preventDefault();
                break;
            case "U":
            case "u":
            case "+":
                setWaiting(true);
                onUebermitteln();
                event.preventDefault();
                break;
            default:
                break;
        }
        event.stopPropagation();
    }, [onClose, onUebermitteln, waiting, setWaiting]);

    useEffect(() => {
        window.addEventListener("keydown", onKeyDown);
        return () => {
            window.removeEventListener("keydown", onKeyDown);
        }
    }, [onKeyDown]);

    useEffect(() => {
        setTimeout(() => {
            if( waitingSeconds <= 0) {
                setWaiting(true);
                onUebermitteln();
            }
            if( waitingSeconds > 0) {
                setWaitingSeconds(waitingSeconds - 1);
            }
        }, 1000);
    }, [waitingSeconds, setWaiting, setWaitingSeconds, onUebermitteln]);

    return<div style={{padding:20, color:"white", fontSize: isSmartphone() ? 20 : 40, textAlign:"center",
        backgroundImage: "linear-gradient(to top, #643161, #019CAD)", height:"100vh"}}>
        <img src="/images/darthelfer_quer_weiss.png" alt="darthelfer.de" title="darthelfer.de"
             style={{marginTop:10, width: 500}}/>
        <div style={{fontSize: 45, padding:20, color:"white", textAlign:"center"}}>
            {unentschieden &&
                <div style={{marginBottom:50}}>
                    Das Spiel endet unentschieden!!!
                </div>
            }
            {gewinner &&
                <div style={{marginBottom:50}}>
                    Der Gewinner ist <span style={{fontWeight:"bolder", marginLeft:20, marginRight:20}}>'{gewinner}'</span>!!!
                </div>
            }
            {waiting ?
                <LinearProgress/>
                :
                <>
                    <div>
                        <Button style={{color: "white", fontSize: isSmartphone() ? 20 : 40}} variant="text" onClick={() => {
                            setWaiting(true);
                            onUebermitteln();
                        }}>Jetzt an darthelfer.de übermitteln? {isDesktop() && "(Drücke: U oder +)"}</Button>
                    </div>
                    <div style={{color: "white", marginBottom: 20, fontSize:16}}>Die Übertragung erfolgt in {waitingSeconds} Sekunden automatisch.</div>
                </>
            }
            {!waiting &&
                <div style={{marginBottom: 20}}>
                    <Button style={{color: "white", fontSize: isSmartphone() ? 20 : 40}} variant="text" onClick={() => onClose()}>Zurück zum
                        Spiel {isDesktop() && "(Drücke: ESC oder *)"}</Button>
                </div>
            }
        </div>
    </div>
}
