import React, {useCallback, useEffect, useRef, useState} from "react";
import {NAVIGATION_ITEM} from "../constants/navigationItems";
import moment from "moment/moment";
import {Button, ThemeProvider} from "@mui/material";
import {CustomFontTheme} from "../x01/CustomFontTheme";
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LegendToggleIcon from '@mui/icons-material/LegendToggle';
import DeleteDialog from "./DeleteDialog";
import {getFromLocalStorage, removeFromLocalStorage} from "../localStorageZugriff";
import {Navigate} from "react-router-dom";
import {isDesktop, isSmartphone} from "../util/GeraeteArtUtil";

export default function GameOverview({offeneAnzeigen}) {

    const [link, setLink] = useState(undefined);

    const [selectedKey, setSelectedKey] = useState(undefined);
    const [games, setGames] = useState([]);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const myRef = useRef(null)

    useEffect(() => {
        if (selectedKey !== undefined) {
            let element = document.getElementById(selectedKey);
            element.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
        }
    }, [selectedKey])

    const loadGames = useCallback(() => {
        let keys = getGameKeys();
        const myMap = new Map();
        for (const key of keys) {
            let game = getFromLocalStorage(key);
            if( (!offeneAnzeigen && (game.abgeschlossen === undefined || !game.abgeschlossen)) ||
                ( offeneAnzeigen &&  game.abgeschlossen !== undefined && game.abgeschlossen )) {
                continue;
            }
            myMap.set(key, game.timestamp);
        }
        const mapSort = new Map([...myMap.entries()].sort((a, b) => {
            return moment(a[1]).diff(moment(b[1]));
        }));
        let tmpGames = [...mapSort.keys()].reverse();
        setGames(tmpGames);
        setSelectedKey(tmpGames[0]);
    }, [offeneAnzeigen]);

    useEffect(() => {
        loadGames();
    }, [loadGames]);

    const showDetails = useCallback(() => {
        if( offeneAnzeigen ) {
            return;
        }
        setLink(NAVIGATION_ITEM.GAMEDETAIL.route + "?id=" + selectedKey);
    }, [offeneAnzeigen, selectedKey]);


    const getKeyIndex = useCallback((key) => {
        for(let counter = 0; counter < games.length; counter++) {
            if (key === games[counter]) {
                return counter;
            }
        }
        return undefined;
    }, [games]);

    const getKeyBefore = useCallback((key) => {
        let index = getKeyIndex(key);
        let naechsterKey = games[index-1];
        if( naechsterKey === undefined) {
            return key;
        }
        return naechsterKey;
    }, [games, getKeyIndex]);

    const getKeyAfter = useCallback((key) => {
        let index = getKeyIndex(key);
        let naechsterKey = games[index+1];
        if( naechsterKey === undefined) {
            return key;
        }
        return naechsterKey;
    }, [games, getKeyIndex]);

    const deleteSelectedGame = useCallback(() =>  {
        let tmpKey = selectedKey;
        let keyAfter = getKeyAfter(selectedKey);
        let keyBefore = getKeyBefore(selectedKey);
        removeFromLocalStorage(selectedKey);
        loadGames();
        if( tmpKey === keyAfter ) {
            // das letzte Item wurde gelöscht
            setSelectedKey(keyBefore);
        } else {
            setSelectedKey(keyAfter);
        }
        setShowDeleteDialog(false);
    }, [selectedKey, getKeyAfter, getKeyBefore, loadGames]);

    const onKeyDown = useCallback(event => {
        event.preventDefault();
        switch (event.key) {
            case "ArrowUp":
            case "ArrowDown":
                let tmpKey;
                if (event.key === "ArrowUp") {
                    tmpKey = getKeyBefore(selectedKey);
                } else {
                    tmpKey = getKeyAfter(selectedKey);
                }
                setSelectedKey(tmpKey);
                break;
            case "d":
            case "D":
                showDetails();
                break;
            case "Enter":
                if (showDeleteDialog) {
                    deleteSelectedGame();
                } else {
                    setLink(NAVIGATION_ITEM.GAME.route + "?id=" + selectedKey);
                }
                break;
            case "Delete":
                setShowDeleteDialog(true);
                break;
            case "Escape":
            case "*":
                setLink(NAVIGATION_ITEM.DASHBOARD.route);
                break;
            default:
                break;
        }
        event.stopPropagation();
    }, [selectedKey, showDeleteDialog, getKeyBefore, getKeyAfter, showDetails, deleteSelectedGame]);

    useEffect(() => {
        window.addEventListener("keydown", onKeyDown);
        return () => {
            window.removeEventListener("keydown", onKeyDown);
        }
    }, [onKeyDown]);

    const getGameKeys = () => {
        let keys = Object.keys(localStorage);
        return keys.filter(key => key.startsWith("dh_game_"));
    }

    const getPlayerNamen = (players) => {
        let tmp = "";
        for (const player of players) {
            if( tmp !== "") {
                tmp += " vs. ";
            }
            tmp += player.name;
        }
        return tmp;
    }


    const getRows = () => {
        let rows = [];
        for (const key of games) {
            let isSelected = key === selectedKey;
            let game = getFromLocalStorage(key);
            let modusValue = game.sets > 1 ? "Sets: " + game.sets + " - " : "";
            if( game.firsttolegs !== undefined && game.firsttolegs !== "") {
                modusValue += "first to legs: " + game.firsttolegs;
            } else if( game.bestoflegs !== undefined && game.bestoflegs !== "") {
                modusValue += "best of legs: " + game.bestoflegs;
            }
            rows.push(<div id={key}
                           key={key}
                           style={{cursor:"pointer", backgroundColor: isSelected ? "#ac1c27" : "white", color: isSelected ? "white" : "#022836" }}
                           onClick={() => {
                               setSelectedKey(key)
                           }}>
                <div style={{fontSize:25, padding:20}}>
                    {moment(game.timestamp).format("DD.MM.YYYY HH:mm")}
                    {isSmartphone() && <br/>}
                    <span style={{marginLeft: isSmartphone() ? 0 : 50}}>{getPlayerNamen(game.players)}</span>
                    {isSmartphone() && <br/>}
                    <span style={{marginLeft: isSmartphone() ? 0 : 50}}>{modusValue}</span>
                    {isSmartphone() && <br/>}
                    {isSelected && <div style={{marginTop:10}}><ThemeProvider theme={CustomFontTheme}>
                        <Button variant="text" style={{color:"#022836"}} startIcon={<OpenInBrowserIcon style={{fontSize:50, color:"#022836"}}/>} onClick={() => window.open(NAVIGATION_ITEM.GAME.route + "?id=" + key, "_self")}>Anzeigen{isDesktop() && " (Enter)"}</Button>
                        {!offeneAnzeigen &&
                            <Button variant="text" style={{color: "#022836"}}
                                    startIcon={<LegendToggleIcon style={{fontSize: 50, color: "#022836"}}/>}
                                    onClick={() => showDetails()}>Statistik{isDesktop() && " (D)"}</Button>
                        }
                        <Button variant="text" style={{color:"#022836"}} startIcon={<DeleteOutlineIcon style={{fontSize:50, color:"#022836"}}/>} onClick={() => setShowDeleteDialog(true)}>Löschen{isDesktop() && " (Entf)"}</Button>
                    </ThemeProvider></div>
                    }
                </div>
                <hr/>
            </div>);
            if (isSelected) {
                rows.push(<span key={"ref_key"} ref={myRef}></span>)
            }
        }
        if( rows.length === 0) {
            return <div className="grid" style={{alignItems: "center", border:"1px solid gray", backgroundColor:"#ac1c27", height:100, marginBottom:20}}>
                <div className="col" style={{justifyContent: "center", alignItems: "center", display:"flex", color:"white"}}>
                    <span style={{fontSize:30}}>Keine Spiele vorhanden...</span>
                </div>
            </div>
        }
        return rows;
    }

    if( link !== undefined ) {
        return <Navigate to={link}/>
    }


    return <div style={{padding:20, backgroundColor:"#022836", minHeight:"100vh", height:"100%"}}>
        <div className="grid" style={{position:"fixed", top:0, left:0, fontSize:30, color:"white"}}>
            <div className="col-fixed" style={{marginTop:20, marginLeft:20, width:10, textAlign:"center"}}>
                {offeneAnzeigen ? "O F F E N E" : "B E E N D E T E"}
            </div>
        </div>
        {isDesktop() && <div style={{position:"fixed", left:5, bottom:55}}>
                <ThemeProvider theme={CustomFontTheme}>
                    <Button variant="text" style={{color:"white"}} onClick={() => window.open(NAVIGATION_ITEM.DASHBOARD.route, "_self")}>Esc</Button>
                </ThemeProvider>
            </div>
        }
        <div style={{position:"fixed", left:5, bottom:10}}>
            <ThemeProvider theme={CustomFontTheme}>
                <Button variant="text" style={{color:"white"}} startIcon={<ExitToAppOutlinedIcon style={{fontSize:50, color:"white"}}/>} onClick={() => window.open(NAVIGATION_ITEM.DASHBOARD.route, "_self")}/>
            </ThemeProvider>
        </div>
        <div style={{marginLeft:50}}>
            {getRows()}
        </div>
        {showDeleteDialog && <DeleteDialog onDelete={() => deleteSelectedGame()} onCancel={() => setShowDeleteDialog(false)}/>}
    </div>
}
