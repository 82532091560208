import {getFromLocalStorage} from "../localStorageZugriff";
import {useEffect, useState} from "react";


export default function AutoScoreComponent({abgeschlossen, onScore}) {

    let [counter, setCounter] = useState(0);
    const autoScoreTime = getFromLocalStorage("dh_autoscore");

    useEffect(() => {
        if( autoScoreTime === undefined ) {
            return ;
        }
        if( abgeschlossen !== null && abgeschlossen !== undefined && abgeschlossen) {
            return;
        }

        setTimeout(() => {
            let n = Math.floor(Math.random() * 70);
            onScore(n);
            setCounter(counter+1);
        }, autoScoreTime);
    }, [counter, abgeschlossen, autoScoreTime, onScore]);

    if(autoScoreTime === undefined) {
        return "";
    }

    return <div style={{width:"100%", height:50, fontSize:25, backgroundColor:"red", color:"white"}}>Autoscore ({autoScoreTime}) is on...</div>
}